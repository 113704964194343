// Custom tooltips

.chartjs-tooltip {
  position: absolute;
  z-index: $zindex-sticky + 1;
  padding: ($spacer * .25) ($spacer * .5);
  color: #fff;
  pointer-events: none;
  background: rgba(0, 0, 0, .7);
  opacity: 0;
  @include transition(all .15s ease);
  transform: translate(-50%, 0);
  @include border-radius($border-radius);
}

.chartjs-tooltip-header {
  margin-bottom: ($spacer * .5);
}

.chartjs-tooltip-header-item {
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
}

.chartjs-tooltip-body-item {
  align-items: center;
  font-size: $font-size-sm;
  white-space: nowrap;
}
